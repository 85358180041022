import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  name: "categoryList",

  data() {
    return {
      categoryList: [],
      searchText: "",
      showLoader: false,
      selected: "",
      selectedId: 0,
      companyList: [],
      company_id: "",
      selectedRows: [],
      sortColumn: "name",
      sortDirection: "asc",
      webUrl: process.env.VUE_APP_BLOG_URL,
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.categoryList.length > 0) {
        return (
          _.where(this.categoryList, { isSelected: true }).length ===
          this.categoryList.length
        );
      }
      return false;
    },
    hasBlogCategoryShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBlogCategoryAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBlogCategoryDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBlogCategoryUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    updateCategoryStatus(category, event) {
      let _vm = this;
      let _msg =
        category.isDisplay == true
          ? "Are you sure you want to Deactivate this Blog category?"
          : "Are you sure you want to Activate this Blog category?";

      let _isDisplay = category.isDisplay == true ? 1 : 0;
      // Display a confirmation dialog
      this.$dialog
        .confirm(_msg)
        .then(function () {
          // Update the category status using an AJAX request
          _vm.axios
            .get("/blog/categories/" + category.id + "/edit")
            .then(function () {
              // Refresh the category list
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById("category-" + category.id).checked =
                category.isDisplay;
            });
        })
        .catch(function () {
          document.getElementById("category-" + category.id).checked =
            category.isDisplay;
        });
    },

    checkUncheckedList(event) {
      this.categoryList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a specific ID is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Iterate through categoryList to find selected elements
        this.categoryList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      _vm.axios
        .delete("/blog/categories/0", { data: { ids: ids } })
        .then(function () {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },
    search(value) {
      // Call the getAll() function to retrieve the data
      this.getAll();
    },
    getQueryString() {
      // Construct the query string for the API request
      let queryString =
        "?company=" +
        this.company_id +
        "&page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      // Return the constructed query string
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Make a GET request to retrieve category data
      this.axios
        .get("/blog/categories" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.categoryList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    CompanySlugData() {
      let _vm = this;
      _vm.getAll();
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.company_id = _vm.companyList[0].id;
          _vm.getAll();
        })
        .catch(function () {});
    },
    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // If customCheck-1 is checked, select all rows and update isSelected property for each element in categoryList
          this.selectedRows = this.categoryList.map(
            (item) => "checkbox" + item.id
          );
          this.categoryList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // If customCheck-1 is unchecked, deselect all rows and update isSelected property for each element in categoryList
          this.selectedRows = [];
          this.categoryList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // If a checkbox other than customCheck-1 is checked, add its id to the selectedRows array
          this.selectedRows.push(e.target.id);
        } else {
          // If a checkbox other than customCheck-1 is unchecked, remove its id from the selectedRows array
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
      // End of the handleCheck function
    },
  },
  mounted() {
    this.getCompany();
  },
};
